<!-- 人口信息新增/编辑弹窗-->
<template>
  <el-dialog
    :z-index="10"
    :title="title"
    :visible.sync="visible"
    width="60%"
    @close="closeModal"
    :close-on-click-modal="false"
  >
    <!-- :close-on-click-modal="false" 阻止点击事件的冒泡 -->
    <!-- 左边竖线样式写法 -->
    <div style="display: flex; align-items: center; margin-bottom: 2%">
      <div style="border-left: 4px solid #556bff">&nbsp;</div>
      <div class="search-title">基本信息</div>
    </div>
    <!-- 基本信息 -->
    <ax-form
      ref="form"
      :formBuilder="formBuilder1"
      @change="identityCardChange"
    >
      <div slot="fileIds">
        <imageUpload ref="img1" :businessId="businessId"></imageUpload>
      </div>
      <!-- 籍贯 -->
      <div slot="nativePlace">
        <a-cascader
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :options="options1"
          placeholder="请选择籍贯"
          @change="onChange1"
          allowClear
          :show-search="{ filter }"
          v-decorator="[
            'options1',
            { rules: [{ required: false, message: '请选择籍贯' }] },
          ]"
        />
        <!-- 必须使用v-decorator才能附上值  -->
      </div>
    </ax-form>
    <div style="display: flex; align-items: center; margin-bottom: 2%">
      <div style="border-left: 4px solid #556bff">&nbsp;</div>
      <div class="search-title">联系信息</div>
    </div>
    <!-- 联系信息 -->
    <ax-form ref="form1" :formBuilder="formBuilder2" @change="buildingChange">
    </ax-form>
    <div style="display: flex; align-items: center; margin-bottom: 2%">
      <div style="border-left: 4px solid #556bff">&nbsp;</div>
      <div class="search-title">户籍信息</div>
    </div>
    <!-- 户籍信息 -->
    <ax-form ref="form2" :formBuilder="formBuilder3" @change="wayChange">
      <!-- 户籍地 -->
      <div slot="residencePlace">
        <a-cascader
          :options="options3"
          placeholder="请选择户籍地"
          change-on-select
          :show-search="{ filter1 }"
          @change="onChange3"
          :fieldNames="{ label: 'label', value: 'label', children: 'children' }"
          allowClear
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          v-decorator="[
            'options3',
            { rules: [{ required: false, message: '请选择' }] },
          ]"
        />
      </div>
    </ax-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="onSubmit"
        style="background-color: #556bff"
        :loading="saveType"
        >保 存</el-button
      >
      <el-button @click="onCancel">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import axForm from "@/components/ax-form"; 
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入", maxLength: 20 },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入姓名" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { placeholder: "请输入", maxLength: 18 },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern:
          /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        message: "请输入正确格式的身份证号",
      },
    ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: true, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请先填写身份证号" }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请先填写身份证号" }],
  },
  {
    label: "民族",
    type: "select",
    model: "nation",
    options: {
      allowClear: true,
      placeholder: "请选择",
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择民族" }],
  },
  {
    label: "文化程度",
    type: "select",
    model: "educationalLevel",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请选择文化程度" }],
  },
  {
    label: "政治面貌",
    type: "select",
    model: "politicalStatus",
    options: {},
    col: { span: 12 },
    rules: [{ required: true, message: "请选择政治面貌" }],
  },
  {
    label: "婚姻状态",
    type: "radio",
    model: "maritalStatus",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请选择婚姻状态" }],
  },
  {
    label: "职业",
    type: "select",
    model: "job",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请选择职业" }],
  },
  {
    label: "兵役状态",
    type: "select",
    model: "militaryStatus",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请选择兵役状态" }],
  },
  {
    label: "宗教信仰",
    type: "select",
    model: "religion",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请选择宗教信仰" }],
  },
  {
    label: "籍贯",
    type: "",
    model: "nativePlace",
    options: {
      allowClear: true,
      placeholder: "请选择",
    },
    col: { span: 12 },
    rules: [{ required: false, message: "请选择籍贯" }],
  },
  {
    label: "人员类型",
    type: "radio",
    model: "type",
    options: {
      allowClear: true,
      options: [
        { label: "本地居民", value: "0" },
        { label: "流入人口", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择人员类型" }],
  },
  {
    label: "个人照片",
    type: "",
    model: "fileIds",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "" }],
  },
];
const formList2 = [
  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: { placeholder: "请输入", maxLength: 11 },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "详细地址",
    type: "",
    model: "address",
    options: { placeholder: "请输入详细地址" },
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请输入" },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择所属网格" }],
  },
  {
    label: "小区",
    type: "select",
    model: "cellId",
    options: { placeholder: "请输入" },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "楼栋",
    type: "select",
    model: "buildingNum",
    options: { placeholder: "请输入" },
    col: { span: 6 },
    rules: [{ required: false, message: "请输入现住区域" }],
  },
  {
    label: "单元",
    type: "select",
    model: "unitNum",
    options: { placeholder: "请输入", disabled: true },
    col: { span: 6 },
    rules: [{ required: false, message: "请输入现住区域" }],
  },
  {
    label: "楼层",
    type: "select",
    model: "floorNum",
    options: { placeholder: "请输入", disabled: true },
    col: { span: 6 },
    rules: [{ required: false, message: "请输入现住区域" }],
  },
  {
    label: "户号",
    type: "select",
    model: "accountNum",
    options: { placeholder: "请输入", disabled: true },
    col: { span: 6 },
    rules: [{ required: false, message: "请输入现住区域" }],
  },
];
const formList3 = [
  {
    label: "是否户主",
    type: "radio",
    model: "flag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择是否户主" }],
  },
  {
    label: "户主身份证号",
    type: "",
    model: "houseHolderCard",
    options: { placeholder: "请输入", maxLength: 18 },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern:
          /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        message: "请输入正确格式的身份证号",
      },
    ],
  },
  {
    label: "与户主关系",
    type: "select",
    model: "houseHolderRelation",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请选择" }],
  },

  {
    label: "户籍地",
    type: "",
    model: "residencePlace",
    options: {},
    col: { span: 24 },
    rules: [{ required: false }],
  },
];
import api from "./api";
import jsonData from "./data.json"; // 导入 JSON 文件
import jsonDataFour from "./data-four.json"; // 导入 JSON 文件
import imageUpload from "./ax-image-modal copy/src/image-upload.vue";
import Cascader from "@/pages/views/Acomponents/cascader";
export default {
  components: {
    imageUpload,
    Cascader,
  },
  data() {
    return {
      options1: jsonData, // 使用导入的 JSON 数据（籍贯）
      options2: [], // 所属网格数据
      options3: jsonDataFour, //户籍地
      visible: false,
      isHouseHolder: false,
      businessId: "",
      id: "",
      title: "",
      disabled: false,
      fileIds: [], //图片数组
      saveType: false,
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      formBuilder3: this.$common.initGridFormData(
        formList3,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 12 } }
      ),
      baseInfoValue: {}, //基本信息
      nativePlace: "", //籍贯
      nativeCode: "", //籍贯
      contactValue: {}, //联系信息
      domicileValue: {}, //户籍信息
      residencePlace: "", //户籍地
      residenceCode: "", //户籍地
      unitNum: "", //单元名称
      unitName: "", //单元名称
      floorNum: "", //楼栋
      buildingNum: "", //楼层
      accountNum: "", //户号
      flag: "", //人员类型
      houseHolderCard: "", //户主身份证号
      houseHolderRelation: "", //与户主关系
      identityCard: "", //身份证号
      gridId: "", //所属网格id
      selectLable: "", //所属网格
      list: [], //所属网格数据源
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  computed: {},
  async mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.saveType = false;
      this.nativePlace = "";
      this.detailedAddress = "";
      this.residencePlace = "";
      this.residenceCode = "";
      this.title = record.title;
      // this.getRegionInfo(); //获取省级行政区划
      this.owningGrid(); //获取所属网格
      this.getCellInfo(); //获取小区信息
      if (record.title === "新增居民信息") {
        this.$nextTick(() => {
          this.$refs.form2.setOptions(["houseHolderRelation"], "hide", false);
          this.$refs.form2.setOptions(
            ["houseHolderRelation"],
            "placeholder",
            "请选择"
          );
        });
      }
      this.$nextTick(() => {
        this.getDictionsary();
        this.$refs.img1.removeFileList();
        this.$refs.form.resetFields();
        this.$refs.form1.resetFields();
        this.$refs.form2.resetFields();
      });
      if (record.title === "编辑居民信息") {
        this.id = record.id;
        this.businessId = record.id;
        this.getPersonInfo(record.id);
        this.$nextTick(() => {
          this.$refs.img1.removeFileList();
        });
      } else {
        this.id = "";
      }
    },
    // 籍贯级联选择器的模糊搜索
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    // 户籍地级联选择器的模糊搜索
    filter1(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    // 获取小区数据
    async getCellInfo() {
      await api.getGridManagement().then((res) => {
        const options = res.data.map((res) => {
          return { label: res.name, value: res.id };
        });
        console.log(options, "小区");

        this.$refs.form1.setFormItemProp("cellId", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
        this.$refs.form1.setFormItemProp("buildingNum", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
    },
    // 户籍地改变回调
    // changeType2() {
    //   this.residencePlace = "";
    //   this.residenceCode = "";
    // },
    // 获取省级行政区划（户籍地）
    // async getRegionInfo() {
    //   const res = await api.getRegionInfo();
    //   console.log(res, "省级");
    //   this.options3 = res.data.map((res1) => {
    //     return {
    //       label: res1.areaName,
    //       value: res1.provinceCode,
    //       isLeaf: false,
    //     };
    //   });
    // },
    // 级联选择器改变的回调(籍贯)
    onChange1(value, selectedOptions) {
      if (Array.isArray(value) && Array.isArray(selectedOptions)) {
        this.nativePlace = value.join("/");
        console.log(value, selectedOptions);
        return value.join("/");
      } else {
        console.error(
          "Expected arrays for value and selectedOptions, but got:",
          value,
          selectedOptions
        );
        return "";
      }
    },
    // 级联选择器改变的回调(户籍地)
    onChange3(value, e) {
      console.log(value, e); //传最后一个id
      this.residencePlace = value;
    },
    // 获取所属网格数据（楼栋 单元 楼层 户号）
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.form1.setFormItemProp("gridId", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 获取字典
    async getDictionsary() {
      // 民族
      await api.dictData({ dicKind: "nation" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form.setFormItemProp("nation", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 宗教
      await api.dictData({ dicKind: "religion" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form.setFormItemProp("religion", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 职业
      await api.dictData({ dicKind: "job" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form.setFormItemProp("job", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 兵役状态
      await api.dictData({ dicKind: "military_status" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form.setFormItemProp("militaryStatus", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 婚姻状态
      await api.dictData({ dicKind: "marital_status" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form.setFormItemProp("maritalStatus", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 文化程度
      await api.dictData({ dicKind: "educational_level" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form.setFormItemProp("educationalLevel", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 政治面貌
      await api.dictData({ dicKind: "political_status" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form.setFormItemProp("politicalStatus", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      //人员关系
      await api.dictData({ dicKind: "personnel_relation" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        if (!this.flag || this.flag === "1") {
          console.log(111);
          this.$refs.form2.setFormItemProp("houseHolderRelation", {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              hide: false,
            },
          });
        } else {
          this.$refs.form2.setFormItemProp("houseHolderRelation", {
            options: {
              hide: true,
            },
          });
        }
      });
    },
    // 身份证号改变的回调
    identityCardChange(value, key) {
      switch (key) {
        case "identityCard":
          this.identityCard = value;
          this.calculateIDCardInfo(value);
          break;
      }
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 楼栋改变的回调
    async buildingChange(e, label, type) {
      console.log(e, type, "871");
      if (label === "buildingNum") {
        this.buildingNum = e;
        if (this.buildingNum) {
          const res = await api.getBulidingManagement(this.buildingNum);
          console.log(res, "370");
          const options = res.data.map((res) => {
            return { label: res.blockCode, value: res.id };
          });
          this.$refs.form1.setFormItemProp("unitNum", {
            options: { options, disabled: false, placeholder: "请选择" },
          });
        }
      }
      if (label === "unitNum") {
        this.unitNum = e;
        if (this.unitNum) {
          const res = await api.getUnitInfoById(this.unitNum);
          console.log(res, "370");
          const options = res.data.map((res) => {
            return {
              label: res.unitName,
              value: res.buildingId,
              unitName: res.unitName,
            };
          });
          console.log(options, "900");
          this.$refs.form1.setFormItemProp("floorNum", {
            options: { options, disabled: false, placeholder: "请选择" },
          });
        }
      }
      // 这一步有点问题不太会
      if (label === "floorNum") {
        this.floorNum = e;
        if (this.floorNum) {
          console.log(this.unitName, "12121212");
          const res = await api.getUnitInfoByUnitName(
            this.unitName,
            this.floorNum
          );
          console.log(res, "378");
          const options = res.data.map((res) => {
            return { label: res.unitName, value: res.buildingId };
          });
          this.$refs.form1.setFormItemProp("accountNum", {
            options: { options, disabled: false, placeholder: "请选择" },
          });
        }
      }
    },
    // 是否户主改变的回调
    wayChange(e, type) {
      if (type === "flag") {
        this.flag = e;
        this.getDictionsary();
        console.log(e);
        // 是户主
        if (e === "0") {
          this.$refs.form2.setOptions(["houseHolderCard"], "hide", true);
          this.$refs.form2.setOptions(["houseHolderRelation"], "hide", true);
        }
        // 不是户主
        if (e === "1") {
          this.$refs.form2.setOptions(["houseHolderCard"], "hide", false);
          this.$refs.form2.setOptions(["houseHolderRelation"], "hide", false);
        }
      }
    },
    // 提交
    async onSubmit() {
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        console.log(value);
        this.fileIds = [];
        const files = this.$refs.img1.getFile();
        const files1 = this.$refs.img1.getFileListAll();
        console.log(files, "185");
        if (files1.length > 0) {
          const ids = files1.map((item) => {
            return item.uid;
          });
          const res = await api.del(ids.join(","));
          console.log(res, "871");
          console.log(files1, "369");
        }
        files.map((item) => {
          if (item.response) {
            this.fileIds.push(item.response.data.id);
          } else {
            this.fileIds.push(item.uid);
          }
        });
        value.fileIds = [...new Set([...this.fileIds])];
        // 籍贯
        if (Array.isArray(this.nativePlace)) {
          //判断是不是数组   是数组就用/拼接
          value.nativePlace = this.nativePlace.join("/");
        } else {
          value.nativePlace = this.nativePlace; //不是数组就直接赋值
        }
        this.baseInfoValue = value;
        this.baseInfoValue.job = value.job || "";
        this.baseInfoValue.educationalLevel = value.educationalLevel || "";
        this.baseInfoValue.militaryStatus = value.militaryStatus || "";
        this.baseInfoValue.religion = value.religion || "";
        delete this.baseInfoValue.options1;
        console.log(this.baseInfoValue, "814");
        if (this.$refs.form1) {
          this.$refs.form1.form.validateFields(async (err, value1) => {
            if (err) return;
            console.log(value1, "827");
            value1.gridId = value1.gridId[value1.gridId.length - 1];
            value1.unitNum = this.unitNum;
            value1.floorNum = this.floorNum;
            value1.buildingNum = this.buildingNum;
            value1.accountNum = this.accountNum;
            this.contactValue = value1;
            console.log(this.contactValue, "832");
            if (this.$refs.form2) {
              this.$refs.form2.form.validateFields(async (err, value2) => {
                if (err) return;
                console.log(this.residenceCode, this.residencePlace, "853");
                console.log(value2, "880");
                value2.residencePlace = this.residencePlace.join("/") || "";
                if (value2.flag === "0") {
                  value2.houseHolderCard = this.identityCard;
                  value2.houseHolderRelation = "";
                }
                this.domicileValue = value2;
                const totalValue = {
                  ...this.baseInfoValue,
                  ...this.contactValue,
                  ...this.domicileValue,
                };
                console.log(totalValue, "375新增数据");
                this.saveType = true;
                if (this.id) {
                  totalValue.id = this.id;
                  try {
                    const res = await api.updateResidentArchives(totalValue);
                    if (res.status === 200) {
                      this.$message.success("修改成功");
                      this.saveType = false;
                      this.closeModal();
                      this.$refs.img1.removeFileList();
                      this.$refs.form.resetFields();
                      this.$refs.form1.resetFields();
                      this.$refs.form2.resetFields();
                    }
                  } catch (error) {
                    this.saveType = false;
                  }
                } else {
                  try {
                    const res = await api.addPeople(totalValue);
                    if (res.status === 200) {
                      this.$message.success("新增成功");
                      this.saveType = false;
                      this.closeModal();
                      this.$refs.img1.removeFileList();
                      this.$refs.form.resetFields();
                      this.$refs.form1.resetFields();
                      this.$refs.form2.resetFields();
                    }
                  } catch (error) {
                    this.saveType = false;
                  }
                }
              });
            }
          });
        }
      });
    },
    onCancel() {
      this.visible = false;
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);

      // 籍贯回显
      const nativePlace = res.data.nativePlace.split("/"); // 将字符串转换为数组
      this.$refs.form.setFieldsValue({
        ...res.data,
        options1: nativePlace,
      });

      // 所在网格回显

      try {
        this.gridId = res.data.gridId; // 假设这是你的目标 ID
        const gridTreeRes = await api.getTreeList();
        if (gridTreeRes.success) {
          const gridTreeData = this.processData(gridTreeRes.data); // 处理数据
          //找到父级 //递归地遍历树结构数据，并构建从根节点到目标节点的路径
          const findNodePath = (data, targetValue, path = []) => {
            for (const node of data) {
              const currentPath = [...path, node.value];
              if (node.value === targetValue) {
                return currentPath;
              }
              if (node.children && node.children.length > 0) {
                const result = findNodePath(
                  node.children,
                  targetValue,
                  currentPath
                );
                if (result) {
                  return result;
                }
              }
            }
            return null;
          };
          // 模拟获取的gridId
          //所有id
          const nodePath = findNodePath(gridTreeData, this.gridId);
          console.log(nodePath, "905");//所有层级id数组
          this.$refs.form1.setFieldsValue({
            ...res.data,
            gridId: nodePath,
          });
        } else {
          console.error("获取网格树数据失败:", gridTreeRes.message);
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
      // 户籍地回显
      // 籍贯回显
      this.residencePlace = res.data.residencePlace.split("/");
      const residencePlace = res.data.residencePlace.split("/"); // 将字符串转换为数组
      this.$refs.form2.setFieldsValue({
        ...res.data,
        options3: residencePlace,
      });

      // 身份证号处理
      this.identityCard = res.data.identityCard;
      this.calculateIDCardInfo(res.data.identityCard); //根据身份证号自动计算年龄 性别 出生日期

      // 是不是户主的页面组件显示控制
      this.flag = res.data.flag; //根据是不是户主判断是否显示隐藏部分字段
      // 以下部分是根据是否户主实现某些字段的显示隐藏
      if (res.data.flag === "0") {
        this.$refs.form2.setOptions(
          ["houseHolderCard", "houseHolderRelation"],
          "hide",
          true
        );
      } else {
        this.$refs.form2.setFieldsValue({
          houseHolderCard: res.data.houseHolderCard,
        });
        this.$refs.form2.setFieldsValue({
          houseHolderRelation: res.data.houseHolderRelation,
        });
        this.$refs.form2.setOptions(["houseHolderCard"], "hide", false);
        this.$refs.form2.setOptions(["houseHolderRelation"], "hide", false);
      }
    },
    // 关闭弹窗
    closeModal() {
      this.businessId = "";
      this.visible = false;
      this.$emit("refsh");
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}

.search-title {
  font-weight: 800;
}
</style>